.logoWrapper {
  transition: transform 0.2s;
  margin-right: 140px;
  /* padding: 0; */
}

.logo {
  max-height: 36px;
}

.logoWrapper:hover {
  transform: scale(1.05);
}

.logoText {
  vertical-align: middle;
  height: 100%;
  margin: auto;
  margin-left: 12px;
  font-weight: 500;
  /* top: 14px; */
  margin-top: 2px;
  position: absolute;
}
