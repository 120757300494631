.root table tr:nth-child(2n) {
  background-color: #c0392b;
}

.root h2 {
  font-size: 1.75em;
}

.root h4 {
  margin-top: 0.5em;
  margin-bottom: 0.25em;
}

@media only screen and (max-width: 1000px) {
  .root h2 {
    font-size: 1.5em;
  }
}
