.root table,
.root th,
.root td,
.root tr {
  border: 0px;
  color: white;
}

.root table {
  max-width: min(100%, 800px);
  margin: auto;
}

.root input {
  width: 16px;
  height: 16px;
}
