.root {
  background-color: #a71100;
  padding: 3.5em;
}

@media only screen and (max-width: 1000px) {
  .root {
    padding: 1.5em;
  }
}
