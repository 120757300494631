.root h1 {
  font-size: 2.5em;
}

.section {
  padding: 3.5em 0;
}

.featureImage {
  max-width: 60%;
  height: 20vh;
  margin: 0 auto;
  margin-top: 1em;
}

.featureHeading {
  font-size: var(--ifm-h3-font-size);
  padding-top: 1.2em;
}

.root td {
  vertical-align: middle;
}

.root a {
  color: white;
}

.previousDownloads table {
  display: table;
}

@media only screen and (max-width: 1000px) {
  .root h1 {
    font-size: 1.75em;
  }
  .section {
    padding: 2em 0;
  }
  .previousDownloads table {
    display: inherit;
  }
}
