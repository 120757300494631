body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*:any-link {
  color: #f1c40f;
}

.highlightColor {
  color: #c0392b;
}

.highlightColorLight {
  color: #f39c12;
}

.hoverLarge {
  transition: transform 0.2s;
}

.hoverLarge:hover {
  transform: scale(1.1);
}

.ant-popover-inner {
  background-color: var(--ifm-color-gray-1000) !important;
}

.ant-popover-message-title {
  color: white !important;
}

.ant-popover-inner {
  background-color: var(--ifm-color-gray-800) !important;
  border-style: solid;
  border-color: var(--ifm-color-gray-800);
  border-width: 1px;
}

.ant-popover-placement-top .ant-popover-arrow {
  background-color: var(--ifm-color-gray-800) !important;
  border-style: solid;
  border-right-color: var(--ifm-color-gray-800) !important;
  border-bottom-color: var(--ifm-color-gray-800) !important;
  border-width: 1px;
}

.ant-popover-placement-bottom .ant-popover-arrow {
  background-color: black;
  border-style: solid;
  border-top-color: var(--ifm-color-gray-800) !important;
  border-left-color: var(--ifm-color-gray-800) !important;
  border-width: 1px;
}

.ant-message-notice-content {
  background-color: black !important;
  font-size: 1rem;
}

.ant-message-notice-content svg {
  margin-top: -50%;
}

.ant-message-custom-content {
  color: white;
}

.ant-modal-content,
.ant-modal-header,
.ant-modal-title,
.ant-modal-close-icon {
  background-color: var(--ifm-color-gray-1000) !important;
  color: white !important;
}
